import { Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Box, styled } from '@mui/system';
import * as React from 'react';
import { CSSProperties, RefObject } from "react";
import { useNavigate } from 'react-router-dom';
import logoImg from '../../img/title-logo.png';
import liveIcon from '../../img/live-nav-icon.svg';
import { BarIcon, LeftChevronIcon, ShopIcon } from '../Icons';
import SearchModal from '../SearchModal';
import { FloatingHeaderWrapper } from "./FloatingHeaderWrapper";
import {
  useGetLivePostQuery
} from '../../api/articles.api';
interface buttonProps {
  fill?: boolean;
  stroke?: boolean;
}

export const CustomIconButton = styled(IconButton)<buttonProps & { backgroundColor?: string; }>(({ fill = true, stroke = false, theme, backgroundColor }) => ({
  ...backgroundColor && { backgroundColor, marginLeft: 10 },
  '& svg': {
    fill: fill ? theme.palette.darkPallet.dark : 'none',
    stroke: stroke ? theme.palette.darkPallet.dark : 'none',
    height: '20px',
    width: '20px',
  },
}));

const headerHeight = 50

const HeaderWrapper = styled(Box)<{ light?: string; }>(({ light, theme }) => ({
  flexShrink: 0,
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: `${headerHeight}px`,
  backgroundImage: light ? 'none' : theme.palette.background.gradient,
  zIndex: 99,
}));

const Logo = styled(Box)(() => ({
  width: '34px',
  height: '34px',
  left: '50%',
  marginLeft: '-17px',
  position: 'absolute',
  '& img': {
    width: '100%',
    position: 'static',
    objectFit: 'cover',
  },
}));

const LogoLive = styled(Box)(() => ({
  width: '25px',
  height: '25px',
  position: 'absolute',
  top: '25%',
  right: '1%',
  '& img': {
    width: '100%',
    position: 'static',
    objectFit: 'cover',
  },
}));

export interface HeaderProps {
  onSearch?: () => void;
  cartIcon?: boolean;
  disableSearch?: boolean;
  backIcon?: boolean;
  logo?: boolean;
  RightComponent?: React.ReactNode;
  style?: CSSProperties;
  backTo?: string | (() => void);
  floating?: RefObject<HTMLDivElement>;
}

const Header = ({
  backIcon = false,
  cartIcon = false,
  // disableSearch = false,
  RightComponent,
  logo = true,
  style,
  backTo,
  floating,
}: HeaderProps) => {
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const [livePost, setLivePost] = React.useState(false);

  const checkLivePosts = async () => {
    const livePostList = await useGetLivePostQuery();
    if (livePostList?.data) {
      setLivePost(true)
    } else {
      setLivePost(false)
    }
  }

  React.useEffect(() => {
    checkLivePosts();
  }, [])

  const onPage = (name: string) => () => {
    navigate(`/${name}`);
  };

  const onBack = () => {
    if (backTo) {
      if (typeof (backTo) === 'string') {
        navigate(backTo);
      } else if (typeof (backTo) === 'function') {
        backTo()
      }
    }
    else { navigate(-1); }
  };

  const onSearchClose = () => {
    setOpen(false);
  };

  // const onSearch = () => {
  //   setOpen(true);
  // };

  const onBar = () => {
    navigate(`/dashboard`);
  };

  const onLogo = () => {
    navigate(`/feed`);
  };

  const onLiveVideo = () => {
    navigate(`/watch-live`);
  };

  return (
    <FloatingHeaderWrapper floating={floating} height={headerHeight}>
      <HeaderWrapper style={style}>
        {backIcon ? (
          <CustomIconButton aria-label='Back' onClick={onBack}>
            <LeftChevronIcon />
          </CustomIconButton>
        ) : (
          <CustomIconButton aria-label='remove' onClick={onBar}>
            <BarIcon />
          </CustomIconButton>
        )}
        {logo && (
          <Logo>
            <img onClick={onLogo} src={logoImg} alt='Logo' />
          </Logo>
        )}
        {livePost && (
          <div>
            <LogoLive>
              <img onClick={onLiveVideo} src={liveIcon} alt='Logo' />
            </LogoLive>
          </div>
        )}
        <div>
          {/* {!disableSearch && (
            <CustomIconButton aria-label='settings' onClick={onSearch}>
              <SearchIcon />
            </CustomIconButton>
          )} */}
          {cartIcon && (
            <CustomIconButton aria-label='cart' onClick={onPage('cart')} stroke={true} fill={false}>
              <ShopIcon />
            </CustomIconButton>
          )}
          {RightComponent}
          <SearchModal open={open} handleClose={onSearchClose} />
        </div>

      </HeaderWrapper>
    </FloatingHeaderWrapper>
  );
};

Header.Light = ({
  title,
  RightComponent,
  backIcon = false,
  onBack,
  hideLogo = false,
  backButtonColor = ''
}: {
  title?: string;
  backIcon?: boolean;
  RightComponent?: React.ReactNode;
  onBack?: () => void;
  hideLogo?: boolean;
  backButtonColor?: string;
}) => {
  return (
    <HeaderWrapper light='true'>
      {backIcon && (
        <CustomIconButton aria-label='Back' onClick={onBack} backgroundColor={backButtonColor}>
          <LeftChevronIcon />
        </CustomIconButton>
      )}
      {!hideLogo && (
        <Logo sx={{ ml: 2 }}>
          <img src={logoImg} alt='Logo' />
        </Logo>
      )}
      <Box sx={{ flex: '1' }}>
        {title && (
          <Typography variant='h6' align='center' fontWeight='700' fontSize='17px' color='lightPallet.lighter'>
            title
          </Typography>
        )}
      </Box>
      {RightComponent}
    </HeaderWrapper>
  );
};

export default Header;
